import React, { Component } from 'react'

import style from './style.module.css'

import { RectWithloader } from '../../'
import { RectWithLoader } from '../../Buttons'

import { auth } from '../../../firebase'



class LoginModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
        
    }

    componentDidMount = async () => {

       
      }

    onUsernameChange = (event) => {

        this.setState({username:event.target.value})
    }

    onPasswordChange = (event) => {
        this.setState({password:event.target.value})
    }

    signIn = () => {


        console.log('doin a sign in')
        
        auth.signInWithEmailAndPassword(this.state.username, this.state.password)
            .then((userCredential) => {
                // Signed in
                var user = userCredential.user;
                console.log('success sign in')
                // ...
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log('error sign in ' + errorMessage)
            });
    }
    render () {


            
return (

    <div className={style.container}>
        <div className={style.content}>
    <h3 className={style.title}>
        Login
        </h3>
        <div className={style.centerBox}>
        <input onChange={this.onUsernameChange} className={style.textField} type="text" id="fname" name="fname"/>
        <input onChange={this.onPasswordChange} className={style.textField} type="text" id="fname" name="fname"/>
        </div>
     <RectWithLoader
     action={()=>{this.signIn()}}
     title='Login'
     />
         <div className={style.closeButton}
                onClick={()=>{this.props.close()}}>
                    X
                    </div>
         </div>
 </div>
       
  )
  }
  }

export default LoginModal