import React, { Component, useContext } from 'react'

import style from './style.module.css'

import { MainFeed, RightBar } from '../../components'

import { UserContext } from '../../data/User'



export default function MyPlayerCard () {
    
        const user = useContext(UserContext);
        let displayName = 'Log in to record stats'
        
        if(user){
        const { email } = user
        displayName = email
        }

            
return (

        <div className={style.container}>
            <h2 className={style.headerTitle}>
                {displayName}
            </h2>
            {user &&
            <div>
            <div className={style.barContainer}>
                <div className={style.barValue}/>
                <div className={style.barNumbers}>
                        2300/3000
                    </div>
            </div>
            <h3 className={style.rankTitle}>
                Rookie
            </h3>
            </div>
            }
        </div>
       
  )
  }
