//http://lvh.me:5001/basketball-debate/us-central1
//https://us-central1-stage-basketball-debate.cloudfunctions.net

const stageLocal = 'http://lvh.me:5001/stage-basketball-debate/us-central1'
const stage = 'https://us-central1-stage-basketball-debate.cloudfunctions.net'
const dev = 0
let url = stage
if(dev === 1){
url = stageLocal
}


export const path = stage