import { path } from './path'

export const getHomePageDebates = new Promise((resolve, reject) => {

   fetch(path + '/getHomePageDebates', {
        
    })
      .then(res => res.json())
      .then(
        (result) => {
          resolve(result.debates)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          reject(error)
        }
      )
})

export const getDebateResponses = (debateId) => {

  console.log('going for responses ' + debateId)
  let body = JSON.stringify({debateId: debateId})
  return new Promise((resolve, reject) => {
  fetch(path + '/getDebateResponses', {
       method: 'POST',
       body:body,
   })
     .then(res => res.json())
     .then(
       (result) => {
         resolve(result.responses)
       },
       // Note: it's important to handle errors here
       // instead of a catch() block so that we don't swallow
       // exceptions from actual bugs in components.
       (error) => {
         console.log('fuckin error was ' + error)
         reject(error)
       }
     )
})
}