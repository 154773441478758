import React, { Component } from 'react'

import { CircleLoader, RingLoader, RotateLoader, DotLoader,
MoonLoader, HashLoader } from 'react-spinners'

import style from './style.module.css'

import { MainFeed, RightBar } from '../../components'
import { getHomePageDebates } from '../../api-routes'



class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded:false,
        }
        
    }

    componentDidMount = async () => {

      

       getHomePageDebates.then((results)=>{
         console.log('wowww debate results' + JSON.stringify(results))
         this.setState({debates:results, loaded:true})
       })
      }

    render () {
      

            
return (
      <div>
        {this.state.loaded ? 
        <div className={style.container}>
            <MainFeed debates={this.state.debates}/>
            <RightBar/>
        </div>
        :
        <div style={{width:'80px', height:'80px', margin:'0 auto', marginTop:'20%', display:'flex', alignItems:'center', justifyContent:'center'}}>
        <MoonLoader color="#eb8c34" style={{margin:0}}/>
        </div>
        }
        </div>
       
  )
  }
  }

export default Home