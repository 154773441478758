// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

const stageConfig = {
    apiKey: "AIzaSyBAGoKVjpcnqp3nKMvfgnvlyzjSWw5pbP0",
    authDomain: "stage-basketball-debate.firebaseapp.com",
    projectId: "stage-basketball-debate",
    appId: "1:509994922408:web:fd76a71366ee229f716d9d"
  };

let config = stageConfig

  // Initialize Firebase
firebase.initializeApp(config);

export const auth = firebase.auth()