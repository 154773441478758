import React, { Component } from 'react'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";

import style from './css/topic.module.css'


const placeholder = 'https://cdn.shopify.com/s/files/1/0915/3014/products/0544_basketball_1_large.jpg?v=1581108009'



class Topic extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
        
    }

    componentDidMount = async () => {

       
    }

    

    render () {

       

            
return (

    
   
        <div className={style.container}>
            <div className={style.header}>
                <img className={style.headerImage} src={placeholder}/>
                <div className={style.headerMain}>
                <div className={style.headerRow}>
                <h2 className={style.headerTitle}>{this.props.debate.title}</h2>
                <h3 className={style.topicDate}>{this.props.debate.date}</h3>
                </div>
                <div className={style.headerRow}>
                    <h4 className={style.topicType}>{this.props.debate.type}</h4>
                    <h4 className={style.pointValue}>{this.props.debate.bigPoints} CP | {this.props.debate.smallPoints} CP</h4>
                    <h4 className={style.timeRemaining}>{this.props.debate.remaining}</h4>
                </div>
                </div>
            </div>
            <div className={style.topicDescription}>
                {this.props.debate.description}
            </div>
            <div className={style.footer}>
            <h5 className={style.answers}>14 Answers</h5>
            <h5 className={style.votes}>122 Votes</h5>
            <Link className={style.joinButton} to={{
                pathname: '/debates/' + this.props.debate.id,
                state: this.props.debate,
            }}>Enter Debate</Link>
        </div>
        </div>
      
    
  )
  }
  }

export default Topic