import React, { Component } from 'react'

import style from './style.module.css'

import { MoonLoader } from 'react-spinners'


class RectWithLoader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading:false,
        }
        
    }

    componentDidMount = async () => {

       
      }

    action = () => {
    this.setState({loading:true})
    this.props.action()
    }

    render () {


            
return (
    <div>
    {this.state.loading ? 
    <div className={style.loaderContainer}
    style={this.props.loaderStyle && this.props.loaderStyle}>
    <MoonLoader size={this.props.loaderSize ? this.props.loaderSize : 30} color={this.props.loaderColor ? this.props.loaderColor : '#eb8c34'} style={{margin:0}}/>
    </div> 
    :
    <div style={this.props.buttonStyle && this.props.buttonStyle} className={style.container} onClick={()=>{this.action()}}>
        {this.props.title}
    </div>
    }
    </div>
       
  )
  }
  }

export default RectWithLoader