import React, { Component } from 'react'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";

import style from './style.module.css'



import { Topic } from '../'

import { Debate } from '../../pages'


class MainFeed extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }

    }

    componentDidMount = async () => {


    }

    render() {

        return (
            <div>
            <div className={style.container}>
            
           
            {this.props.debates && this.props.debates.map((item, index) => {

                return <Topic key={item.id} debate={item}/>
            })
            }
          
        
                
                
            </div>
            </div>
            

        )
    }
}

export default MainFeed