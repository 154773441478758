import React, { Component } from 'react'
import { LoginModal } from '../'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";

import style from './style.module.css'


const logoSrc= 'https://cdn.iconscout.com/icon/free/png-256/debate-6-542541.png'

class TopNav extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLogin:false,
        }

    }

    componentDidMount = async () => {


    }

    loginClicked = () => {
        this.setState({showLogin:true})
    }

    hideLogin = () => {
        this.setState({showLogin:false})
    }

    render() {

        return (
            <div>
            <div className={style.container}>
                 <Link className={style.logoCenter} to='/'>
                <img src={logoSrc} className={style.logoCenter} style={{left:0}}/>
                </Link>
                <div className={style.rightBox}>
                    <div className={style.navButton} onClick={()=>{this.loginClicked()}}>
                        Login
                    </div>
                </div>
            </div>
             {this.state.showLogin && <LoginModal close={this.hideLogin}/>}
            </div>

        )
    }
}

export default TopNav