import React, { Component } from 'react'

import style from './style.module.css'


const placeholder = 'https://pbs.twimg.com/media/DgQd06aUwAAUDH6?format=jpg&name=large'

class StandardResponse extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
        
    }

    componentDidMount = async () => {

       
      }

  
   

    render () {

       

            
return (

    
   
        <div className={style.container}>
            <div className={style.header}>
                <div className={style.username}>
                {this.props.response.username}
                </div>
                <div className={style.timestamp}>
                {this.props.response.timestmap}
                </div>
                <div className={style.rightHeaderContainer}>
                    <div className={style.agreeButton}>
                        Agree
                    </div>
                    <div className={style.agreeButton}>
                        Disagree
                    </div>
                    <div className={style.rectButton}>
                        Ride or Die
                    </div>
                </div>
            </div>
            <div className={style.badgeContainer}>
            <img className={style.userBadge} src={placeholder}/>
            </div>
            <div className={style.contentBox}>
                <div className={style.contentLongAnswer}>
                    {this.props.response.content}
                </div>
            </div>
        </div>
      
    
  )
  }
  }

export default StandardResponse